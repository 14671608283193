<template>
  <div class="stat-container">
    <div class="stat-num">
      <el-tag type="primary" size="normal" effect="dark" v-for="(item,i) in count" :key="i">{{item.label}}:{{item.count}}</el-tag>
    </div>
    <div class="stat-tab">
      <el-tabs type="border-card" v-model="tabName" @tab-click="changeTab" v-loading="exportLoading" element-loading-text="数据导出中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <!-- 设备tan栏 -->
        <el-tab-pane label="已录入设备" v-loading="loading1" name="device">
          <div class="header">
            <el-form :inline="true" class="demo-form-inline">
              <el-form-item>
                <el-popover placement="right" width="800" v-model="searchShow1">
                  <el-form :inline="true" :model="searchDevice" class="demo-form-inline">
                    <el-form-item label="单位名称">
                      <el-input v-model="searchDevice.unitName" placeholder="请输入" size="small" clearable style="width:150px"></el-input>
                    </el-form-item>
                    <el-form-item label="设备特征码">
                      <el-input v-model="searchDevice.equipmentCode" autocomplete="off" size="small" placeholder="请输入" clearable style="width:150px"></el-input>
                    </el-form-item>
                    <el-form-item label="安装人员">
                      <el-input v-model="searchDevice.username" autocomplete="off" size="small" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="行政区域">
                      <el-cascader v-model="areaCodes" size="small" placeholder="请选择" :options="areaList" :props="{ checkStrictly: true }" clearable style="width:150px"></el-cascader>
                    </el-form-item>
                    <el-form-item label="设备类型">
                      <el-select v-model="searchDevice.typeId" placeholder="请选择" size="small" clearable style="width:150px">
                        <el-option v-for="(item,i) in typeList" :key="i" :label="item.typeName" :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="状态">
                      <el-select v-model="searchDevice.status" placeholder="请选择" size="small" clearable>
                        <el-option v-for="(item,i) in [{id:1,label:'在线'},{id:0,label:'离线'}]" :key="i" :label="item.label" :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="时间">
                      <el-date-picker v-model="date1" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small">
                      </el-date-picker>
                    </el-form-item>
                  </el-form>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" @click="searchShow1 = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="getDevice">查询</el-button>
                  </div>
                  <el-button type="primary" size="small" slot="reference" icon="el-icon-search">查询</el-button>
                </el-popover>
              </el-form-item>
              <el-form-item>
                <el-tooltip content="导出设备" placement="top" effect="dark">
                  <el-button type="warning" size="small" icon="el-icon-download" @click="exportDevice">导出单位</el-button>
                </el-tooltip>
              </el-form-item>
            </el-form>
          </div>
          <div class="content">
            <el-table :data="deviceList" stripe style="width: 100%" height="100%">
              <el-table-column type="expand">
                <template slot-scope="props">
                  <el-row :gutter="20">
                    <el-col :span="12" :offset="0">
                      <el-form label-position="left" inline class="demo-table-expand">
                        <el-form-item label="设备类型" :label-width="formLabelWidth">
                          <span>{{props.row.typeName}}</span>
                        </el-form-item>
                        <el-form-item label="设备型号" :label-width="formLabelWidth">
                          <span>{{props.row.modelName}}</span>
                        </el-form-item>
                        <el-form-item label="所在单位" :label-width="formLabelWidth">
                          <span>{{props.row.unitName}}</span>
                        </el-form-item>
                        <el-form-item label="安装建筑物" :label-width="formLabelWidth">
                          <span>{{props.row.installName}}</span>
                        </el-form-item>
                        <el-form-item label="联系电话" :label-width="formLabelWidth">
                          <span>{{props.row.contactPhone}}</span>
                        </el-form-item>
                        <el-form-item label="状态" :label-width="formLabelWidth">
                          <el-tag type="danger" size="normal" v-show="props.row.status===0">离线</el-tag>
                          <el-tag type="success" size="normal" v-show="props.row.status===1">在线</el-tag>
                          <el-tag type="primary" size="normal" v-show="props.row.status===2">维保</el-tag>
                          <el-tag type="info" size="normal" v-show="props.row.status===3">拆除</el-tag>
                          <el-tag type="warning" size="normal" v-show="props.row.status===4">故障</el-tag>
                        </el-form-item>
                        <el-form-item label="图片" :label-width="formLabelWidth">
                          <el-image :src="props.row.imageUrl" fit="fill" :lazy="true" :preview-src-list="[props.row.imageUrl]"></el-image>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="12" :offset="0">
                      <el-form label-position="left" inline class="demo-table-expand">
                        <el-form-item label="安装时间" :label-width="formLabelWidth">
                          <span>{{props.row.installTime|timeFormat}}</span>
                        </el-form-item>
                        <el-form-item label="通讯方式" :label-width="formLabelWidth">
                          <span>{{props.row.communicateTypeName}}</span>
                        </el-form-item>
                        <el-form-item label="设备特征码" :label-width="formLabelWidth">
                          <span>{{props.row.equipmentCode}}</span>
                        </el-form-item>
                        <el-form-item label="基站特征码" :label-width="formLabelWidth">
                          <span>{{props.row.stationCode}}</span>
                        </el-form-item>
                        <el-form-item label="最近数据通讯时间" :label-width="formLabelWidth">
                          <span>{{props.row.lastContactTime|timeFormat}}</span>
                        </el-form-item>
                        <el-form-item label="描述" :label-width="formLabelWidth">
                          <span>{{props.row.description}}</span>
                        </el-form-item>
                        <el-form-item label="经纬度" :label-width="formLabelWidth">
                          <span>{{props.row.lng}} # {{props.row.lat}}</span>
                          <el-button type="text" size="default" @click="open('location',{lng:props.row.lng,lat:props.row.lat})" icon="el-icon-location"></el-button>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column prop="typeName" label="设备类型" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="unitName" label="所在单位" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-button type="text" size="default" @click="open('unit',scope.row)">{{scope.row.unitName}}</el-button>
                </template>
              </el-table-column>
              <el-table-column prop="unitTypeName" label="监管类别" show-overflow-tooltip width="100">
              </el-table-column>
              <el-table-column prop="equipmentCode" label="设备特征码" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="lastContactTime" label="状态" show-overflow-tooltip width="80px">
                <template slot-scope="props">
                  <el-tag type="danger" size="normal" v-show="props.row.status===0">离线</el-tag>
                  <el-tag type="success" size="normal" v-show="props.row.status===1">在线</el-tag>
                  <el-tag type="primary" size="normal" v-show="props.row.status===2">维保</el-tag>
                  <el-tag type="info" size="normal" v-show="props.row.status===3">拆除</el-tag>
                  <el-tag type="warning" size="normal" v-show="props.row.status===4">故障</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="contactPhone" label="联系电话" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="installTime" label="安装时间" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row.installTime|timeFormat}}
                </template>
              </el-table-column>
              <el-table-column prop="username" label="安装人员" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="equipmentId" label="绑定设备" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-button type="text" size="default" @click="open('info',scope.row.equipmentId)" v-show="scope.row.equipmentId>0">查看设备</el-button>
                  <el-button type="text" size="default" v-show="scope.row.equipmentId<0">暂未绑定</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="footer">
            <el-pagination @current-change="changeDevicePage" :current-page.sync="devicePage" :page-size="10" layout="total, prev, pager, next, jumper" :total="deviceTotal">
            </el-pagination>
          </div>
        </el-tab-pane>
        <!-- 单位 tab -->
        <el-tab-pane label="已录入单位" v-loading="loading2" name="unit">
          <div class="header">
            <el-form :inline="true" class="demo-form-inline">
              <el-form-item>
                <el-popover placement="right" width="800" v-model="searchShow2">
                  <el-form :inline="true" :model="searchUnit" class="demo-form-inline">
                    <el-form-item label="单位名称">
                      <el-input v-model="searchUnit.unitName" autocomplete="off" size="small" placeholder="请输入单位名称" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="单位类别">
                      <el-select v-model="searchUnit.typeId" placeholder="请选择单位类别" size="small" clearable style="width:150px">
                        <el-option v-for="(item,i) in unitTypeList" :key="i" :label="item.typeName" :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="行政区域">
                      <el-cascader v-model="areaCodes2" size="small" placeholder="请选择" :options="areaList" :props="{ checkStrictly: true }" clearable style="width:150px"></el-cascader>
                    </el-form-item>
                    <el-form-item label="时间">
                      <el-date-picker v-model="date2" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small">
                      </el-date-picker>
                    </el-form-item>
                  </el-form>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" @click="searchShow2 = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="getUnit">查询</el-button>
                  </div>
                  <el-button type="primary" size="small" slot="reference" icon="el-icon-search">查询</el-button>
                </el-popover>
              </el-form-item>
              <el-form-item>
                <el-tooltip content="导出单位" placement="top" effect="dark">
                  <el-button type="warning" size="small" icon="el-icon-download" @click="exportUnit">导出单位</el-button>
                </el-tooltip>
              </el-form-item>
            </el-form>
          </div>
          <div class="content">
            <el-table :data="unitList" stripe style="width: 100%" height="100%">
              <el-table-column type="expand">
                <template slot-scope="props">
                  <el-row :gutter="20">
                    <el-col :span="12" :offset="0">
                      <el-form label-position="left" inline class="demo-table-expand">
                        <el-form-item label="单位名称" :label-width="formLabelWidth">
                          <span>{{props.row.unitName}}</span>
                        </el-form-item>
                        <el-form-item label="地址" :label-width="formLabelWidth">
                          <span>{{props.row.address}}</span>
                        </el-form-item>
                        <el-form-item label="行政区域" :label-width="formLabelWidth">
                          <span>{{props.row.areaName}}</span>
                        </el-form-item>
                        <el-form-item label="法人" :label-width="formLabelWidth">
                          <span>{{props.row.legalName}}</span>
                        </el-form-item>
                        <el-form-item label="法人电话" :label-width="formLabelWidth">
                          <span>{{props.row.legalPhone}}</span>
                        </el-form-item>
                        <el-form-item label="法人身份证" :label-width="formLabelWidth">
                          <span>{{props.row.legalIdCard}}</span>
                        </el-form-item>
                        <el-form-item label="图片" :label-width="formLabelWidth">
                          <el-image :src="props.row.imageUrl" fit="fill" :lazy="true" :preview-src-list="[props.row.imageUrl]"></el-image>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="12" :offset="0">
                      <el-form label-position="left" inline class="demo-table-expand">
                        <el-form-item label="单位类型" :label-width="formLabelWidth">
                          <span>{{props.row.unitTypeName}}</span>
                        </el-form-item>
                        <el-form-item label="组织机构代码" :label-width="formLabelWidth">
                          <span>{{props.row.organizationCode}}</span>
                        </el-form-item>
                        <el-form-item label="单位编码" :label-width="formLabelWidth">
                          <span>{{props.row.unitCoding}}</span>
                        </el-form-item>
                        <el-form-item label="单位性质" :label-width="formLabelWidth">
                          <span>{{props.row.unitNatureName}}</span>
                        </el-form-item>
                        <el-form-item label="所属行业" :label-width="formLabelWidth">
                          <span>{{props.row.industryName}}</span>
                        </el-form-item>
                        <el-form-item label="单位介绍" :label-width="formLabelWidth">
                          <span>{{props.row.unitCoding}}</span>
                        </el-form-item>
                        <el-form-item label="经纬度" :label-width="formLabelWidth">
                          <span>{{props.row.lng}} # {{props.row.lat}}</span>
                          <el-button type="text" size="default" @click="open('location',{lng:props.row.lng,lat:props.row.lat})" icon="el-icon-location"></el-button>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column prop="unitName" label="单位名称" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="legalName" label="法人" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="legalPhone" label="法人电话" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="address" label="地址" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="unitNatureName" label="单位性质" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="industryName" label="所属行业" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="organizationCode" label="组织机构代码" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="unitCoding" label="单位编码" show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </div>
          <div class="footer">
            <el-pagination @current-change="changeUnitPage" :current-page.sync="unitPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="unitTotal">
            </el-pagination>
          </div>
        </el-tab-pane>
        <!-- 建筑物 tab -->
        <el-tab-pane label="已录入建筑物" v-loading="loading3" name="building">
          <div class="header">
            <el-form :inline="true" class="demo-form-inline">
              <el-form-item>
                <el-popover placement="right" width="800" v-model="searchShow3">
                  <el-form :inline="true" :model="searchBuilding" class="demo-form-inline">
                    <el-form-item label="建筑物名称">
                      <el-input clearable size="small" v-model="searchBuilding.buildingName" autocomplete="off" placeholder="请输入建筑物名称"></el-input>
                    </el-form-item>
                    <el-form-item label="行政区域">
                      <el-cascader v-model="areaCodes3" size="small" placeholder="请选择" :options="areaList" :props="{ checkStrictly: true }" clearable></el-cascader>
                    </el-form-item>
                    <el-form-item label="时间">
                      <el-date-picker v-model="date3" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small">
                      </el-date-picker>
                    </el-form-item>
                  </el-form>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" @click="searchShow3 = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="getBuilding">查询</el-button>
                  </div>
                  <el-button type="primary" size="small" slot="reference" icon="el-icon-search">查询</el-button>
                </el-popover>
              </el-form-item>
              <el-form-item>
                <el-tooltip content="导出建筑物" placement="top" effect="dark">
                  <el-button type="warning" size="small" icon="el-icon-download" @click="exportBuilding">导出建筑物</el-button>
                </el-tooltip>
              </el-form-item>
            </el-form>
          </div>
          <div class="content">
            <el-table :data="buildingList" stripe style="width: 100%" height="100%">
              <el-table-column type="expand">
                <template slot-scope="props">
                  <el-row :gutter="20">
                    <el-col :span="12" :offset="0">
                      <el-form label-position="left" inline class="demo-table-expand">
                        <el-form-item label="建筑物名称" :label-width="formLabelWidth">
                          <span>{{props.row.buildingName}}</span>
                        </el-form-item>
                        <el-form-item label="地址" :label-width="formLabelWidth">
                          <span>{{props.row.address}}</span>
                        </el-form-item>
                        <el-form-item label="行政区域" :label-width="formLabelWidth">
                          <span>{{props.row.areaName}}</span>
                        </el-form-item>
                        <el-form-item label="面积" :label-width="formLabelWidth">
                          <span>{{props.row.area}}</span>
                        </el-form-item>
                        <el-form-item label="楼层数" :label-width="formLabelWidth">
                          <span>{{props.row.floorNum}}</span>
                        </el-form-item>
                        <el-form-item label="耐火等级" :label-width="formLabelWidth">
                          <span>{{props.row.fireResistLevelName}}</span>
                        </el-form-item>
                        <el-form-item label="火灾危险性" :label-width="formLabelWidth">
                          <span>{{props.row.fireDangerName}}</span>
                        </el-form-item>
                        <el-form-item label="是否为高层建筑" :label-width="formLabelWidth">
                          <span>{{props.row.isTall===1?'是':'否'}}</span>
                        </el-form-item>
                        <el-form-item label="楼栋长" :label-width="formLabelWidth">
                          <span>{{props.row.managerName}}</span>
                        </el-form-item>
                        <el-form-item label="楼栋长电话" :label-width="formLabelWidth">
                          <span>{{props.row.managerPhone}}</span>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="12" :offset="0">
                      <el-form label-position="left" inline class="demo-table-expand">
                        <el-form-item label="建筑物性质" :label-width="formLabelWidth">
                          <span>{{props.row.natureName}}</span>
                        </el-form-item>
                        <el-form-item label="建筑机构" :label-width="formLabelWidth">
                          <span>{{props.row.structureName}}</span>
                        </el-form-item>
                        <el-form-item label="建筑类型" :label-width="formLabelWidth">
                          <span>{{props.row.buildTypeName}}</span>
                        </el-form-item>
                        <el-form-item label="经纬度" :label-width="formLabelWidth">
                          <span>{{props.row.lng}} # {{props.row.lat}}</span>
                          <el-button type="text" size="default" @click="open('location',{lng:props.row.lng,lat:props.row.lat})" icon="el-icon-location"></el-button>
                        </el-form-item>
                        <el-form-item label="单元数" :label-width="formLabelWidth">
                          <span>{{props.row.unitNum}}</span>
                        </el-form-item>
                        <el-form-item label="辖区民警" :label-width="formLabelWidth">
                          <span>{{props.row.localPolice}}</span>
                        </el-form-item>
                        <el-form-item label="民警电话" :label-width="formLabelWidth">
                          <span>{{props.row.policePhone}}</span>
                        </el-form-item>
                        <el-form-item label="备注" :label-width="formLabelWidth">
                          <span>{{props.row.remark}}</span>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column prop="buildingName" label="建筑物名称" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="address" label="地址" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="floorNum" label="楼层数" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="managerName" label="楼栋长" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="managerPhone" label="楼栋长电话" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="policePhone" label="民警电话" show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </div>
          <div class="footer">
            <el-pagination @current-change="changeBuildingPage" :current-page.sync="buildingPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="buildingTotal">
            </el-pagination>
          </div>
        </el-tab-pane>
        <!-- 室外水源 tab -->
        <el-tab-pane label="已录入室外水源" v-loading="loading4" name="water">
          <div class="header">
            <el-form :inline="true" class="demo-form-inline">
              <el-form-item>
                <el-popover placement="right" width="800" v-model="searchShow4">
                  <el-form :inline="true" :model="searchWater" class="demo-form-inline">
                    <el-form-item label="关键词">
                      <el-input v-model="searchWater.keyword" autocomplete="off" size="small" placeholder="请输入关键词" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="行政区域">
                      <el-cascader v-model="areaCodes4" size="small" placeholder="请选择行政区域" :options="areaList" :props="{ checkStrictly: true }" clearable></el-cascader>
                    </el-form-item>
                    <el-form-item label="安装人员">
                      <el-input v-model="searchWater.userName" autocomplete="off" size="small" placeholder="请输入安装人员" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="时间">
                      <el-date-picker v-model="date4" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small">
                      </el-date-picker>
                    </el-form-item>
                  </el-form>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" @click="searchShow4 = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="getWater">查询</el-button>
                  </div>
                  <el-button type="primary" size="small" slot="reference" icon="el-icon-search">查询</el-button>
                </el-popover>
              </el-form-item>
              <el-form-item>
                <el-tooltip content="导出水源" placement="top" effect="dark">
                  <el-button type="warning" size="small" icon="el-icon-download" @click="exportWater">导出水源</el-button>
                </el-tooltip>
              </el-form-item>
            </el-form>
          </div>
          <div class="content">
            <el-table :data="waterList" stripe style="width: 100%" height="100%" v-loading="loading1">
              <el-table-column type="expand">
                <template slot-scope="props">
                  <el-row :gutter="20">
                    <el-col :span="12" :offset="0">
                      <el-form label-position="left" inline class="demo-table-expand">
                        <el-form-item label="图片" :label-width="formLabelWidth">
                          <el-image :src="props.row.imageUrl" fit="fill" :lazy="true" :preview-src-list="[props.row.imageUrl]"></el-image>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="12" :offset="0">
                    </el-col>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column prop="location" label="地址" show-overflow-tooltip width="200">
              </el-table-column>
              <el-table-column prop="pipeType" label="管网形式" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-tag type="primary" size="size">{{scope.row.pipeType===0?'枝状':'环状'}}</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="type" label="形式" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-tag type="primary" size="size">{{scope.row.type===0?'地上':'地下'}}</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="pressure" label="管网压力" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row.pressure}}Mpa
                </template>
              </el-table-column>
              <el-table-column prop="width" label="管网直径" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row.width}}mm
                </template>
              </el-table-column>
              <el-table-column prop="status" label="状态" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-tag type="primary" size="size" v-show="scope.row.status===0">良好</el-tag>
                  <el-tag type="primary" size="size" v-show="scope.row.status===1">不可用</el-tag>
                  <el-tag type="primary" size="size" v-show="scope.row.status===2">损坏</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="waterUnit" label="供水单位" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row.unitName||'暂无单位'}}
                </template>
              </el-table-column>
              <el-table-column prop="userName" label="安装工人" show-overflow-tooltip width="100">
              </el-table-column>
            </el-table>
          </div>
          <div class="footer">
            <el-pagination @current-change="changeWaterPage" :current-page.sync="waterPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="waterTotal">
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 地图 dialog -->
    <el-dialog class="dialog" :visible.sync="mapShow" width="50%" top="5%">
      <div class="map">
        <baidu-map class="bmView" :scroll-wheel-zoom="true" :center="location" style="height:500px" :zoom="zoom" ak="ahVb0clSVLITXPpNieRI7DXL47tgHvcE" @ready="handler">
          <bm-view style="width: 100%; height:100px; flex: 1"></bm-view>
          <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
          <bm-marker :position="location" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
          </bm-marker>
        </baidu-map>
      </div>
    </el-dialog>
    <!-- 设备抽屉 -->
    <el-drawer :title="unitName" :visible.sync="unitShow" direction="rtl" :before-close="handleClose">
      <el-form label-position="left" inline class="drawer-form">
        <el-form-item label="单位名称" :label-width="formLabelWidth">
          <span>{{unit.unitName}}</span>
        </el-form-item>
        <el-form-item label="单位类型" :label-width="formLabelWidth">
          <span>{{unit.unitTypeName}}</span>
        </el-form-item>
        <el-form-item label="组织机构代码" :label-width="formLabelWidth">
          <span>{{unit.organizationCode}}</span>
        </el-form-item>
        <el-form-item label="单位编码" :label-width="formLabelWidth">
          <span>{{unit.unitCoding}}</span>
        </el-form-item>
        <el-form-item label="单位性质" :label-width="formLabelWidth">
          <span>{{unit.unitNatureName}}</span>
        </el-form-item>
        <el-form-item label="所属行业" :label-width="formLabelWidth">
          <span>{{unit.industryName}}</span>
        </el-form-item>
        <el-form-item label="单位介绍" :label-width="formLabelWidth">
          <span>{{unit.unitCoding}}</span>
        </el-form-item>
        <el-form-item label="地址" :label-width="formLabelWidth">
          <span>{{unit.address}}</span>
        </el-form-item>
        <el-form-item label="行政区域" :label-width="formLabelWidth">
          <span>{{unit.areaName}}</span>
        </el-form-item>
        <el-form-item label="法人" :label-width="formLabelWidth">
          <span>{{unit.legalName}}</span>
        </el-form-item>
        <el-form-item label="法人电话" :label-width="formLabelWidth">
          <span>{{unit.legalPhone}}</span>
        </el-form-item>
        <el-form-item label="法人身份证" :label-width="formLabelWidth">
          <span>{{unit.legalIdCard}}</span>
        </el-form-item>
        <el-form-item label="经纬度" :label-width="formLabelWidth">
          <span>{{unit.lng}} # {{unit.lat}}</span>
          <el-button type="text" size="default" @click="open('location',{lng:unit.lng,lat:unit.lat})" icon="el-icon-location"></el-button>
        </el-form-item>
        <el-form-item label="单位照片" :label-width="formLabelWidth">
          <el-image :src="unit.unitImage" fit="fill" :lazy="true" style="width: 100px; height: 100px" :preview-src-list="[unit.unitImage]"></el-image>
        </el-form-item>
      </el-form>
    </el-drawer>
    <el-drawer title="NB水压" :visible.sync="deviceShow" direction="rtl" :before-close="handleClose">
      <el-form label-position="left" inline class="drawer-form">
        <el-form-item label="单位名称" :label-width="formLabelWidth">
          <span>{{unit.unitName}}</span>
        </el-form-item>
        <el-form-item label="单位类型" :label-width="formLabelWidth">
          <span>{{unit.unitTypeName}}</span>
        </el-form-item>
        <el-form-item label="组织机构代码" :label-width="formLabelWidth">
          <span>{{unit.organizationCode}}</span>
        </el-form-item>
        <el-form-item label="单位编码" :label-width="formLabelWidth">
          <span>{{unit.unitCoding}}</span>
        </el-form-item>
        <el-form-item label="单位性质" :label-width="formLabelWidth">
          <span>{{unit.unitNatureName}}</span>
        </el-form-item>
        <el-form-item label="所属行业" :label-width="formLabelWidth">
          <span>{{unit.industryName}}</span>
        </el-form-item>
        <el-form-item label="单位介绍" :label-width="formLabelWidth">
          <span>{{unit.unitCoding}}</span>
        </el-form-item>
        <el-form-item label="地址" :label-width="formLabelWidth">
          <span>{{unit.address}}</span>
        </el-form-item>
        <el-form-item label="行政区域" :label-width="formLabelWidth">
          <span>{{unit.areaName}}</span>
        </el-form-item>
        <el-form-item label="法人" :label-width="formLabelWidth">
          <span>{{unit.legalName}}</span>
        </el-form-item>
        <el-form-item label="法人电话" :label-width="formLabelWidth">
          <span>{{unit.legalPhone}}</span>
        </el-form-item>
        <el-form-item label="法人身份证" :label-width="formLabelWidth">
          <span>{{unit.legalIdCard}}</span>
        </el-form-item>
        <el-form-item label="经纬度" :label-width="formLabelWidth">
          <span>{{unit.lng}} # {{unit.lat}}</span>
          <el-button type="text" size="default" @click="open('location',{lng:unit.lng,lat:unit.lat})" icon="el-icon-location"></el-button>
        </el-form-item>
        <el-form-item label="单位照片" :label-width="formLabelWidth">
          <el-image :src="unit.unitImage" fit="fill" :lazy="true" style="width: 100px; height: 100px" :preview-src-list="[unit.unitImage]"></el-image>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmView from 'vue-baidu-map/components/map/MapView.vue'
import BmGeolocation from 'vue-baidu-map/components/controls/Geolocation'
import BmMarker from 'vue-baidu-map/components/overlays/Marker'
export default {
  components: {
    BaiduMap,
    BmView,
    BmGeolocation,
    BmMarker
  },
  data () {
    return {
      areaCodes: [],
      areaCodes2: [],
      areaCodes3: [],
      areaCodes4: [],
      date1: [],
      date2: [],
      date3: [],
      date4: [],
      searchShow1: false,
      searchShow2: false,
      searchShow3: false,
      searchShow4: false,
      mapShow: false,
      unitShow: false,
      deviceShow: false,
      formLabelWidth: '130px',
      location: {
        lng: 0,
        lat: 0
      },
      zoom: 18,
      // 数量
      count: [],
      // 表格列表数据
      deviceList: [],
      deviceTotal: 0,
      devicePage: 1,
      unitList: [],
      unitTotal: 0,
      unitPage: 1,
      buildingList: [],
      buildingTotal: 0,
      buildingPage: 1,
      waterList: [],
      waterTotal: 0,
      waterPage: 1,
      searchDevice: {
        date: []
      },
      searchUnit: {},
      searchBuilding: {},
      searchWater: {},
      typeList: [],
      areaList: [],
      unitTypeList: [],
      unitOptions: [],
      loading1: false,
      loading2: false,
      loading3: false,
      loading4: false,
      exportLoading: false,
      tabName: 'device',
      unitName: '',
      unit: {},
      device: {}
    }
  },
  created () {
    this.getInstallCount()
    this.getDevice()
    this.getDeviceType()
    this.getTypeList()
    this.getAreaList()
  },
  methods: {
    changeTab () {
      switch (this.tabName) {
        case 'device':
          this.getDevice()
          break
        case 'unit':
          this.getUnit()
          break
        case 'building':
          this.getBuilding()
          break
        case 'water':
          this.getWater()
          break
      }
    },
    // 获取安装数量
    async getInstallCount () {
      const { data: result } = await this.$axios.get('/user/count')
      if (result.code === 200) {
        this.count = [
          {
            label: '已录入设备',
            count: result.data[0].equipmentCount
          },
          {
            label: '已录入单位',
            count: result.data[0].unitCount
          },
          {
            label: '已录入建筑物',
            count: result.data[0].buildingCount
          },
          {
            label: '已录入室外水源',
            count: result.data[0].hydrantCount
          }
        ]
      }
    },
    // 设备列表
    async getDevice () {
      const data = {
        pageNo: this.devicePage,
        pageSize: 10,
        ...this.searchDevice,
        areaCode: this.areaCodes[this.areaCodes.length - 1]
      }
      if (this.date1) {
        if (this.date1.length > 0) {
          data.startTime = this.date1[0]
          data.endTime = this.date1[1]
        }
      }
      this.loading1 = true
      const { data: result } = await this.$axios.get('/equipment/listByInstall', { params: data })
      if (result.code === 200) {
        this.deviceList = result.data.result
        this.deviceTotal = result.data.total
        this.loading1 = false
      }
    },
    // 单位列表
    async getUnit () {
      const data = {}
      if (this.searchUnit.unitName) {
        data.unitName = this.searchUnit.unitName
      }
      if (this.searchUnit.typeId) {
        data.typeId = this.searchUnit.typeId
      }
      if (this.areaCodes2.length > 0) {
        data.areaCode = this.areaCodes2[this.areaCodes2.length - 1]
      }
      if (this.date2) {
        if (this.date2.length > 0) {
          data.startTime = this.date2[0]
          data.endTime = this.date2[1]
        }
      }
      data.pageNo = this.unitPage
      data.pageSize = 10
      this.loading2 = true
      const { data: result } = await this.$axios.get('/unit/listByAppCount', { params: data })
      if (result.code === 200) {
        this.unitList = result.data.result
        this.unitTotal = result.data.total
        this.loading2 = false
      }
    },
    // 建筑物列表
    async getBuilding () {
      const data = {}
      if (this.searchBuilding.buildingName) {
        data.buildingName = this.searchBuilding.buildingName
      }
      if (this.areaCodes3.length > 0) {
        data.areaCode = this.areaCodes3[this.areaCodes3.length - 1]
      }
      if (this.date3) {
        if (this.date3.length > 0) {
          data.startTime = this.date3[0]
          data.endTime = this.date3[1]
        }
      }
      data.pageNo = this.buildingPage
      data.pageSize = 10
      this.loading3 = true
      const { data: result } = await this.$axios.get('/building/listByAppCount', { params: data })
      if (result.code === 200) {
        this.buildingList = result.data.result
        this.buildingTotal = result.data.total
        this.loading3 = false
      }
    },
    // 表格初始化
    async getWater () {
      const data = {}
      if (this.searchWater.keyword) {
        data.keyword = this.searchWater.keyword
      }
      if (this.searchWater.userName) {
        data.userName = this.searchWater.userName
      }
      if (this.areaCodes4.length > 0) {
        data.areaCode = this.areaCodes4[this.areaCodes4.length - 1]
      }
      if (this.date4) {
        if (this.date4.length > 0) {
          data.startTime = this.date4[0]
          data.endTime = this.date4[1]
        }
      }
      data.pageNo = this.waterPage
      data.pageSize = 10
      this.loading4 = true
      const { data: result } = await this.$axios.get('/fireHydrant/list', { params: data })
      if (result.code === 200) {
        this.loading4 = false
        this.waterList = result.data.result
        console.log(this.waterList)
        this.waterTotal = result.data.total
      } else {
        this.loading4 = false
        this.$message.error(result.msg)
      }
    },
    // 查找设备类型
    async getDeviceType () {
      const { data: result } = await this.$axios.get('/equipment-type/list')
      if (result.code === 200) {
        this.typeList = result.data
      }
    },
    // 获取类型列表
    async getTypeList () {
      const { data: result } = await this.$axios.get('/unit-type/list')
      if (result.code === 200) {
        this.unitTypeList = result.data
      }
    },
    // 查找行政区域
    async getAreaList () {
      const { data: result } = await this.$axios.get('/area/listByUser')
      if (result.code === 200) {
        if (result.data.length === 0) {
          this.isLoad = true
          return
        }
        this.areaList = this.changeKey(result.data)
      } else {
        this.$message.error(result.msg)
      }
    },
    changeKey (array) {
      array.forEach((item, index) => {
        [item.value, item.areaCode] = [item.areaCode, item.value]
        if (item.children) {
          item.children.forEach((subItem, index2) => {
            [subItem.value, subItem.areaCode] = [subItem.areaCode, subItem.value]
            if (subItem.children) {
              subItem.children.forEach((subItem2, index) => {
                [subItem2.value, subItem2.areaCode] = [subItem2.areaCode, subItem2.value]
              })
            }
          })
        }
      })
      return array
    },
    // 分页
    changeDevicePage (val) {
      this.devicePage = val
      this.getDevice()
    },
    changeUnitPage (val) {
      this.unitPage = val
      this.getUnit()
    },
    changeBuildingPage (val) {
      this.buildingPage = val
      this.getBuilding()
    },
    changeWaterPage (val) {
      this.waterPage = val
      this.getWater()
    },
    async open (flag, row) {
      switch (flag) {
        case 'location':
          this.location = row
          this.mapShow = true
          break
        case 'unit': {
          const { data: result } = await this.$axios.get(`/unit/${row.unitId}`)
          if (result.code === 200) {
            this.unit = result.data
            this.unitName = row.unitName
            this.unitShow = true
          }
          break
        }
        case 'info': {
          const { data: result } = await this.$axios.get('/equipment/list', { params: { id: row } })
          if (result.code === 200) {
            this.device = result.data
            this.deviceShow = true
          }
          break
        }
      }
    },
    // 地图初始化
    handler ({ BMap, map }) {
      var geolocation = new BMap.Geolocation()
      geolocation.getCurrentPosition((r) => {
        this.location.lng = r.point.lng
        this.location.lat = r.point.lat
      })
    },
    // 动态搜索
    async remoteMethod (query) {
      const { data: result } = await this.$axios.get('/unit/listAllUnit', { params: { unitName: query } })
      if (result.code === 200) {
        this.unitOptions = result.data
      }
    },
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => { })
    },
    // 导出Excel
    async exportDevice () {
      this.exportLoading = true
      const data = {}
      if (this.searchDevice.unitName) {
        data.unitName = this.searchDevice.unitName
      }
      if (this.searchDevice.typeId) {
        data.typeId = this.searchDevice.typeId
      }
      if (this.searchDevice.equipmentCode) {
        data.equipmentCode = this.searchDevice.equipmentCode
      }
      if (this.searchDevice.username) {
        data.userName = this.searchDevice.username
      }
      if (this.areaCodes.length > 0) {
        data.areaCode = this.areaCodes[this.areaCodes.length - 1]
      }
      if (this.date1) {
        if (this.date1.length > 0) {
          data.startTime = this.date1[0]
          data.endTime = this.date1[1]
        }
      }
      const result = await this.$axios.post('/util/exportEquipment', data, { responseType: 'blob' })
      if (result.data.type === 'application/json') {
        this.exportLoading = false
        this.$message.error('数据为空,不能导出')
        return
      }
      if (!result.data.message) {
        this.exportLoading = false
      } else {
        this.exportLoading = false
        this.$message.error(result.data.message)
        return
      }
      const blob = new Blob([result.data], { type: 'application/octet-stream' })
      if ('download' in document.createElement('a')) {
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = '设备信息.xlsx' // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      } else {
        navigator.msSaveBlob(blob, '设备信息.xlsx')
      }
    },
    // 导出Excel
    async exportUnit () {
      this.exportLoading = true
      const data = {}
      if (this.searchUnit.unitName) {
        data.unitName = this.searchUnit.unitName
      }
      if (this.searchUnit.typeId) {
        data.typeId = this.searchUnit.typeId
      }
      if (this.areaCodes2.length > 0) {
        data.areaCode = this.areaCodes2[this.areaCodes2.length - 1]
      }
      if (this.date2) {
        if (this.date2.length > 0) {
          data.startTime = this.date2[0]
          data.endTime = this.date2[1]
        }
      }
      const result = await this.$axios.post('/util/exportUnit', data, { responseType: 'blob' })
      if (result.data.type === 'application/json') {
        this.exportLoading = false
        this.$message.error('数据为空,不能导出')
        return
      }
      if (!result.data.message) {
        this.exportLoading = false
      } else {
        this.exportLoading = false
        this.$message.error(result.data.message)
        return
      }
      const blob = new Blob([result.data], { type: 'application/octet-stream' })
      if ('download' in document.createElement('a')) {
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = '单位信息.xlsx' // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      } else {
        navigator.msSaveBlob(blob, '单位信息.xlsx')
      }
    },
    // 导出Excel
    async exportBuilding () {
      this.exportLoading = true
      const data = {}
      if (this.searchBuilding.buildingName) {
        data.buildingName = this.searchBuilding.buildingName
      }
      if (this.areaCodes3.length > 0) {
        data.areaCode = this.areaCodes3[this.areaCodes3.length - 1]
      }
      if (this.date3) {
        if (this.date3.length > 0) {
          data.startTime = this.date3[0]
          data.endTime = this.date3[1]
        }
      }
      const result = await this.$axios.post('/util/exportBuilding', data, { responseType: 'blob' })
      if (result.data.type === 'application/json') {
        this.exportLoading = false
        this.$message.error('数据为空,不能导出')
        return
      }
      if (!result.data.message) {
        this.exportLoading = false
      } else {
        this.exportLoading = false
        this.$message.error(result.data.message)
        return
      }
      const blob = new Blob([result.data], { type: 'application/octet-stream' })
      if ('download' in document.createElement('a')) {
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = '建筑物信息.xlsx' // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      } else {
        navigator.msSaveBlob(blob, '建筑物信息.xlsx')
      }
    },
    async exportWater () {
      this.exportLoading = true
      const data = {}
      if (this.searchWater.keyword) {
        data.keyword = this.searchWater.keyword
      }
      if (this.areaCodes4.length > 0) {
        data.areaCode = this.areaCodes4[this.areaCodes4.length - 1]
      }
      if (this.date4) {
        if (this.date4.length > 0) {
          data.startTime = this.date4[0]
          data.endTime = this.date4[1]
        }
      }
      const result = await this.$axios.post('/util/exportHydrant', data, { responseType: 'blob' })
      if (result.data.type === 'application/json') {
        this.exportLoading = false
        this.$message.error('数据为空,不能导出')
        return
      }
      if (!result.data.message) {
        this.exportLoading = false
      } else {
        this.exportLoading = false
        this.$message.error(result.data.message)
        return
      }
      const blob = new Blob([result.data], { type: 'application/octet-stream' })
      if ('download' in document.createElement('a')) {
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = '室外水源信息.xlsx' // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      } else {
        navigator.msSaveBlob(blob, '室外水源信息.xlsx')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.stat-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .stat-num {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
    .el-tag {
      margin-right: 10px;
    }
  }
  .stat-tab {
    flex: 14;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    .el-tabs {
      width: 100%;
      height: 100%;
      background-color: rgba(30, 74, 115, 0.5);
      border: none;
      ::v-deep .el-tabs__header {
        background-color: rgba(7, 63, 112, 0.6);
        .el-tabs__item {
          color: #fff;
        }
        .is-active {
          background-color: rgba(30, 74, 115, 0.9);
          color: #409eff;
          border-right-color: #dcdfe6;
          border-left-color: #dcdfe6;
        }
      }
      .el-tab-pane {
        height: 100%;
        padding: 5px 5px 0 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        overflow: hidden;
        background-color: transparent;
        .header {
          flex: 1;
          display: flex;
          align-items: center;
          .el-button {
            margin-right: auto;
          }
          .el-form {
            margin-right: auto;
            height: 40px;
            ::v-deep .el-form-item__label {
              color: #fff;
            }
            ::v-deep .el-input__inner {
              background-color: rgba(7, 104, 159, 0.15);
              color: #fff;
            }
          }
        }
        .content {
          flex: 10;
          box-sizing: border-box;
          .el-table {
            background-color: transparent;
            ::v-deep tr,
            ::v-deep th {
              background-color: transparent;
              color: #fff;
              .el-table__expand-icon {
                color: #fff;
              }
            }
            ::v-deep .el-table--enable-row-hover,
            ::v-deep .hover-row > td,
            ::v-deep tr:hover > td {
              background-color: rgba(7, 104, 159, 0.15) !important;
            }
            ::v-deep .el-table__row--striped > td {
              background-color: rgba(7, 104, 159, 0.15) !important;
            }
          }
          ::v-deep .el-table__expanded-cell {
            background-color: transparent;
          }
        }
        .footer {
          flex: 1;
          display: flex;
          align-items: center;
          margin-right: auto;
          .el-pagination {
            margin-right: auto;
            color: #fff;
            background-color: transparent;
            ::v-deep .el-pagination__total,
            ::v-deep .el-pagination__jump {
              color: #fff;
            }
            ::v-deep .btn-prev,
            ::v-deep .btn-next {
              background-color: transparent;
              color: #fff;
            }
            ::v-deep .el-pager li {
              background-color: transparent !important;
            }
            ::v-deep .btn-quicknext {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.demo-table-expand {
  font-size: 0;
  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    ::v-deep  label {
      color: #99a9bf !important;
    }
    .el-image {
      width: 150px;
      height: 150px;
    }
  }
}
.drawer-form {
  font-size: 0;
  padding: 10px 40px;
  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    ::v-deep  label {
      color: #99a9bf !important;
      float: left;
    }
    ::v-deep .el-form-item__content {
      float: left;
    }
  }
}
::v-deep  .el-table__expanded-cell {
  padding: 0 50px;
}
.dialog {
  margin: 0;
  padding: 10px;
  ::v-deep  .el-dialog__header {
    display: none !important;
  }
  .map {
    width: 100%;
    height: 500px !important;
    .bmView {
      height: 100% !important;
      > div:first-child {
        height: 500px !important;
      }
    }
  }
}
::v-deep  :focus {
  outline: 0;
}
.el-drawer__header {
  margin-bottom: 0;
}
</style>
